import * as React from 'react';
import { FunctionField, RaRecord, FieldProps } from 'react-admin';
import { format } from 'date-fns';

interface IFieldProps extends FieldProps {
  displayTime: boolean;
}

const ReceiveMethodField = ({ displayTime, ...props }: IFieldProps) => {
  return (
    <FunctionField
      {...props}
      render={(order: RaRecord) => {
        if (order.receive_method === 'ups_dropoff') {
          return "UPS Drop-off Service"
        } else if (order.receive_method === 'shipping_label') {
          return "UPS Shipping Label"
        } else if (order.receive_method === 'diy_shipping') {
          return "DIY Mail-In"
        } else if (['standard_courier', 'ondemand_courier'].includes(order.receive_method)) {
          let datetime;
          if (displayTime) {
            const start = new Date(order.receipt_shipment.scheduled_at);
            if (order.receive_method === 'ondemand_courier') {
              const end = new Date(start);
              end.setHours(end.getHours() + 2);
              datetime = `(${format(start, "MMM d @ HH:mm ")} - ${format(end, 'HH:mm')})`;
            } else {
              datetime = format(new Date(start), "MMM d");
            }
          }
          let value = order.receive_method === 'ondemand_courier' ? 'On-Demand Pickup' : 'Standard Pickup'
          if (datetime) {
            value += ` ${datetime}`
          }
          return value
        } else if (order.receive_method === 'store_dropoff') {
          return 'Store Dropoff'
        } else if (order.receive_method === 'locker_dropoff') {
          return 'Golocker Dropoff'
        }
      }}
    />
  )
}
export default ReceiveMethodField;
